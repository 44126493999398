<template>
  <div class="container mt-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a @click="$router.push({ name: 'main' })">{{ $t('menu.home') }}</a>
        </li>
        <li class="breadcrumb-item">
          <a @click="$router.push({ name: 'news' })">{{ $t('menu.news') }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page" v-if="data">
          {{ data.title[trad] }}
        </li>
      </ol>
    </nav>
    <div class="card" v-if="data">
      <div class="row">
        <div class="col">
          <div class="row justify-content-between">
            <div class="col-5">
              <div class="categorie">
                {{ data.category.title[trad] }}
              </div>
              <hr>
            </div>
            <div class="col-2 col-md-1">
              <a-select
                :default-value="trad"
                v-model="trad"
                style="width: 4, 5rem; float: right"
                @change="handleChange"
              >
                <a-select-option value="fr"> Fr </a-select-option>
                <a-select-option v-if="data.title.en" value="en"> En </a-select-option>
                <a-select-option v-if="data.title.ar" value="ar"> ع </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="date">
            <i class="fe fe-calendar"></i>
            {{ moment(data.createdAt).locale('fr', localization).format('DD/MM/YYYY HH:mm') }}
          </div>
          <h3 class="title">
            {{ data.title[trad] }}
          </h3>
          <div v-for="(file, index) in data.files" :key="index">
            <div>
              <a @click="inc('download')" :href="apiUrl + file" target="_blank"
                >
                <button type="button" class="btn btn-danger btn-lg btn3d">
             {{ $t('general.download') }} <i class="ml-2 fe fe-download"></i>
            </button>
              </a>
              <div class="downloads">{{ data.download + ' ' + $t('general.downloads') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col image">
          <img :src="apiUrl + data.coverImg" :alt="data.title[trad]" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr />
          <div class="description" v-if="data.desc" v-html="data.desc[trad]"></div>
        </div>
      </div>
      <hr />
      <div class="row justify-content-between">
        <div class="col-10">
          <span class="keys">Mot Clés :</span>
          <span class="key" v-for="tag in data.tags" :key="tag">{{ tag }}</span>
        </div>
        <div class="col-1">
          <div class="watched">{{ data.seen }} <i class="fe fe-eye"></i></div>
        </div>
      </div>
    </div>
    <div v-else class="m-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-grow text-primary" role="status"></div>
      </div>
    </div>
  </div>
</template>
<script>
import apiAxios from '@/services/axios'
import axios from 'axios'
import moment from 'moment'
import localization from 'moment/locale/fr'

export default {
  name: 'Single',
  data: () => ({
    data: null,
    apiUrl: process.env.VUE_APP_API_URL,
    localization,
    trad: 'fr',
  }),
  created() {
  document.querySelector("meta[property='og:url']").setAttribute('content','https://www.ites.tn' + this.$route.fullPath)
    this.getData()
  },
  methods: {
    moment,
    handleChange(value) {
      this.trad = value
    },
    getData() {
      apiAxios
        .post('/actualites/filter', {
          query: { _id: this.$route.params.id, status: 'active' },
        })
        .then(res => {
          if(res.data[0].title.en && this.$i18n.locale == 'en-US') {
              this.trad = 'en'
          }

          if(res.data[0].title.ar && this.$i18n.locale == 'ar-EG') {
              this.trad = 'ar'
          }
          
          this.data = res.data[0]
          this.inc("seen")
          document.querySelector("meta[property='og:image']").setAttribute('content',this.apiUrl + res.data[0].coverImg)
          

        })
        .finally(() => {
          //this.tableLoading=false
        })
    },
   async inc(val){
      //ip control
      fetch('https://www.cloudflare.com/cdn-cgi/trace')
        .then((response) => response.text())
        .then((response) => {
          response = response
            .trim()
            .split('\n')
            .reduce(function (obj, pair) {
              pair = pair.split('=')
              return (obj[pair[0]] = pair[1]), obj
            }, {})
            let data = {
              seenIps: response.ip,
              downloadIps:response.ip,
            }
      axios
        .post(this.apiUrl + '/public/actualite/'+ this.$route.params.id +'/inc/' + val,data)
        .catch(e => console.log(e))
        })   
    },
    trimWords(value, number) {
      if (!value) return ''
      if(value.split(" ").length < number) return value
      return (
        value
          .split(' ')
          .splice(0, number)
          .join(' ') + '...'
      )
    },
    ToText(HTML) {
      var input = HTML
      return input
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
        .replace(/<[^>]+?>/g, '')
        .replace(/\s+/g, ' ')
        .replace(/ /g, ' ')
        .replace(/>/g, ' ')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.image > img {
  width: 100%;
  height: 500px;
  object-fit: contain;
  margin: 20px 0;
}

.title {
  margin-bottom: 15px;
}

.card {
  border-radius: 0;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.categorie {
  font-size: 14px;
  font-style: italic;
  text-align: left;
}

hr{
  border-bottom: 2px solid $primary;
  width: 7em;
  margin: 0;
}

.date {
  color: $primary;
}

.icons a {
  padding-left: 7px;
  padding-right: 7px;
  padding: 10px;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  background-color: $primary;
  color: white;
}
.icons a:hover {
  background: white;
  background-color: rgba(0, 0, 0, 0.05);
  color: $primary !important;
}

.downloads {
  font-size: 12px;
  font-style: italic;
}

.watched {
  color: $primary;
  align-items: center;
  align-self: center;
  align-content: center;
  font-size: 18px;
  font-weight: 600;
}

.keys {
  color: $primary;
  font-size: 16px;
  font-weight: 600;
}

.key {
  font-size: 17px;
  margin-left: 5px;
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn-danger {
  box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #aa0000,
    0 8px 8px 1px rgba(0, 0, 0, 0.5) !important;
  background-color: #d73814;
}

.btn3d.btn-danger:active,
.btn3d.btn-danger.active {
  box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.3) !important;
  background-color: #d73814;
}
</style>
